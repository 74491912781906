const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
      api: "https://localhost:5001/",
      domainUserSide: "http://localhost:3001",
      domainAdminSide: "https://admin.vinhphucip.cgis.asia",
      domainName: "vinhphucip.cgis.asia",
      doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
      doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/wms",
  },
  production: {
      api: "https://vinhphucip-api.cgis.asia/",
      domainUserSide: "http://vinhphucip.cgis.asia",
      domainAdminSide: "https://admin.vinhphucip.cgis.asia",
      domainName: "vinhphucip.cgis.asia",
      doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
      doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/wms",
  },
};

module.exports = apiEnvironment[env];
