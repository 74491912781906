/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./header.scss";
import "./main-nav.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLock,
  faSignOutAlt,
  faCheck,
  faTimes,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import UrlCollect from "../../common/url-collect";
import ViLanguage from "../../languages/vi";
import {
  DomainUserSide,
  DomainAdminSide,
  APIUrlDefault,
  TokenKey,
  getUserInfo,
  removeCookies,
} from "../../utils/configuration";
import * as statementActions from "../../redux/store/statement/statement.store";
import * as appActions from "../../core/app.store";
import * as acountAction from "../../redux/store/account/account.store";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import {
  NotificationMessageType,
} from "../../utils/configuration";

import BannerView from "./banner.view";

const LanguageCollect = ViLanguage;

function RenderModalComponent(props) {
  const { infoAcount, isShowUserInfoModal, handleCloseUserInfoModal, onLogout, showLoading } = props;
  const [file, setFile] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [isShowChangePassword, setIsShowChangePassword] = useState(false);
  const { register, handleSubmit, errors, getValues, watch, clearErrors, setError } = useForm({ mode: "all", reValidateMode: "onBlur" });

  const password = useRef({});
  password.current = watch("password", "");

  const onChangePassword = () => {
    const { password, confirmPassword } = getValues();
    password === confirmPassword ? clearErrors(["confirmPassword"]) : setError("confirmPassword", { type: "validate" })
  }

  const onSubmit = (data) => {
    clearErrors();
    if (!data.oldPassword && !data.password && !file) { isShowChangePassword && setIsShowChangePassword(!isShowChangePassword); handleCloseUserInfoModal(); return };
    if (data.oldPassword && !data.password) {
      setError("password", { type: "validate" });
      return;
    }
    if (data.password && !data.oldPassword) {
      setError("oldPassword", { type: "validate" });
      return;
    }
    showLoading(true);
    const value = {
      id: infoAcount.id,
      currentPassword: data.oldPassword,
      newPassword: data.password,
      file: avatar
    }
    acountAction.UpdateUserAccount(value).then((res) => {
      if (res && res.content) {
        if (data.oldPassword && data.password) {
          ShowNotification(
            viVN.Success.ChangePasswordSuccess,
            NotificationMessageType.Success
          );
        } else {
          ShowNotification(
            viVN.Success.ChangeAvatarSuccess,
            NotificationMessageType.Success
          )
        }
      }
      isShowChangePassword && setIsShowChangePassword(!isShowChangePassword);
      handleCloseUserInfoModal();
      showLoading(false);
    }).catch((err) => {
      showLoading(false);
      ShowNotification(
        viVN.Errors[(err && err.errorType)],
        NotificationMessageType.Error)
    })
  }
  const handleChangeImage = (event) => {
    if (event.target.files[0]) {
      setFile(URL.createObjectURL(event.target.files[0]));
      setAvatar(event.target.files[0]);
    }
  }
  const handleOnCloseModal = () => {
    isShowChangePassword && setIsShowChangePassword(!isShowChangePassword);
    handleCloseUserInfoModal();
  }
  useEffect(() => {
    setFile(null);
  }, [isShowUserInfoModal])
  return (
    <Modal
      show={isShowUserInfoModal}
      onHide={handleOnCloseModal}
      backdrop="static"
      keyboard={false}
      centered
      size="xl"
      className="modal-user-info"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="mb-0">Thông tin người dùng</h5>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-4 text-center">
              {!file ? <img
                src={(infoAcount.avatar || infoAcount.avatar !== "null") ? APIUrlDefault + infoAcount.avatar : "https://www.edmundsgovtech.com/wp-content/uploads/2020/01/default-picture_0_0.png"}
                alt="Avatar"
                className="img-fluid rounded-circle img-avatar"
              /> : <img
                  src={file ? file : "https://www.edmundsgovtech.com/wp-content/uploads/2020/01/default-picture_0_0.png"}
                  alt="Avatar"
                  className="img-fluid rounded-circle img-avatar"
                />
              }
              <div className="position-relative">
                <button className="btn btn-change-avatar w-75 border mt-4 text-uppercase font-weight-bold">
                  Thay ảnh
                </button>

                <input
                  type="file"
                  name="mediaFile"
                  accept=".png, .jpg, .jpeg"
                  className="media-file"
                  onChange={handleChangeImage}
                ></input>
              </div>
            </div>
            <div className="col-8">
              <h5 className="text-uppercase">
                <FontAwesomeIcon icon={faUser} size="sm" className="mr-2" />
                  Thông tin chung
                </h5>
              <div className="form-group row no-gutters">
                <label className="col-3 col-form-label">Tài khoản</label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={infoAcount.email}
                  />
                </div>
              </div>

              <div className="form-group row no-gutters">
                <label className="col-3 col-form-label">Họ và tên</label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={infoAcount.fullName}
                  />
                </div>
              </div>

              <div className="form-group row no-gutters">
                <label className="col-3 col-form-label">Email</label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={infoAcount.email}
                  />
                </div>
              </div>

              <h5 className="text-uppercase cursor-pointer" onClick={() => setIsShowChangePassword(!isShowChangePassword)}>
                <FontAwesomeIcon icon={faKey} size="sm" className="mr-2" />
                  Đổi mật khẩu
                </h5>
              {isShowChangePassword && (<div>
                <div className="form-group row no-gutters">
                  <label className="col-3 col-form-label">Mật khẩu cũ</label>
                  <div className="col-9">
                    <input type="password" name="oldPassword" onChange={() => clearErrors(["oldPassword"])} placeholder="Nhập mật khẩu" ref={register} className="form-control" />
                    {errors.oldPassword && errors.oldPassword.type === "validate" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>
                </div>

                <div className="form-group row no-gutters">
                  <label className="col-3 col-form-label">Mật khẩu mới</label>
                  <div className="col-9">
                    <input
                      type="password"
                      ref={register({
                        minLength: 8,
                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
                      })}
                      onChange={onChangePassword}
                      name="password"
                      className="form-control"
                      placeholder="Nhập mật khẩu mới"
                    />
                    {errors.password && errors.password.type === "validate" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                    {errors.password && errors.password.type === "minLength" && (
                      <span className="error">Mật khẩu tối thiểu 8 ký tự</span>
                    )}
                    {errors.password && errors.password.type === "pattern" && (
                      <span className="error">
                        Có it nhất 1 ký tự viết thường, hoa và ký tự đặc biệt
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-group row no-gutters">
                  <label className="col-3 col-form-label">
                    Nhập lại mật khẩu mới
                  </label>
                  <div className="col-9">
                    <input
                      type="password"
                      ref={register({
                        validate: (value) =>
                          value === password.current || "Mật khẩu không trùng khớp",
                      })}
                      name="confirmPassword"
                      className="form-control"
                      placeholder="Nhập lại mật khẩu mới"
                    />
                    {errors.confirmPassword &&
                      errors.confirmPassword.type === "validate" && (
                        <span className="error">Mật khẩu không khớp</span>
                      )}
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleOnCloseModal} variant="secondary" >
            <FontAwesomeIcon icon={faTimes} size="sm" className="mr-2" />
            Hủy
          </Button>
          <Button type="submit" variant="primary" >
            <FontAwesomeIcon icon={faCheck} size="sm" className="mr-2" />
            Lưu
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

class HeaderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: getUserInfo() ? true : false,
      planningType: [],
      isShowUserInfoModal: false,
      infoAcount: null
    };
  }

  componentWillMount() {
    this.getAllPlanningType();
  }

  getUserAccountDetail() {
    this.props.showLoading(true);
    acountAction.GetUserAccountDetail().then((res) => {
      this.setState({ infoAcount: res && res.content ? res.content : null })
      this.props.showLoading(false);
    }).catch((err) => {
      this.props.showLoading(false);
      ShowNotification(
        viVN.Errors[(err && err.errorType)],
        NotificationMessageType.Error
      );
      console.log("err", err);

    });
  }

  getAllPlanningType() {
    this.props.showLoading(true);
    this.props
      .getAllPlanningType()
      .then((res) => {
        this.setState({
          planningType:
            res && res.content && res.content && res.content.length > 0
              ? res.content
              : [],
        });
        this.props.showLoading(false);
      })
      .catch((err) => {
        this.props.showLoading(false);
        console.log("err", err);
        ShowNotification(
          viVN.Errors[(err && err.errorType)],
          NotificationMessageType.Error
        );
      });
  }

  onLogout() {
    removeCookies(TokenKey.token);
    window.location.replace(DomainUserSide);
  }

  //--- User info modal
  handleOpenUserInfoModal = () => {
    this.getUserAccountDetail();
    this.setState({
      isShowUserInfoModal: true,
    });
  };

  handleCloseUserInfoModal = () => {
    this.setState({
      isShowUserInfoModal: false,
    });
  };



  render() {
    const PATHNAME = this.props.match.path;
    const isLogin = this.state.isLogin;

    return (
      <div className="header-container" id="header-map-page-container">
        <BannerView />
        <nav className="main-nav navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-toggler"  data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M24.703 19.4414H7.31687C6.60031 19.4414 6.01953 18.8606 6.01953 18.144C6.01953 17.4275 6.60031 16.8467 7.31687 16.8467H24.7025C25.4191 16.8467 25.9999 17.4275 25.9999 18.144C25.9999 18.8606 25.4195 19.4414 24.703 19.4414Z"
                        fill="white"/>
                    <path
                        d="M24.703 11.2978H7.31687C6.60031 11.2978 6.01953 10.717 6.01953 10.0005C6.01953 9.2839 6.60031 8.70312 7.31687 8.70312H24.7025C25.4191 8.70312 25.9999 9.2839 25.9999 10.0005C26.0003 10.717 25.4195 11.2978 24.703 11.2978Z"
                        fill="white"/>
                    <path
                        d="M24.703 3.15425H7.31687C6.60031 3.15425 6.01953 2.57347 6.01953 1.85691C6.01953 1.14035 6.60031 0.55957 7.31687 0.55957H24.7025C25.4191 0.55957 25.9999 1.14035 25.9999 1.85691C25.9999 2.57347 25.4195 3.15425 24.703 3.15425Z"
                        fill="white"/>
                    <path
                        d="M1.74233 3.6995C2.70459 3.6995 3.48466 2.91943 3.48466 1.95717C3.48466 0.994911 2.70459 0.214844 1.74233 0.214844C0.780067 0.214844 0 0.994911 0 1.95717C0 2.91943 0.780067 3.6995 1.74233 3.6995Z"
                        fill="white"/>
                    <path
                        d="M1.74233 11.7425C2.70459 11.7425 3.48466 10.9624 3.48466 10.0001C3.48466 9.03788 2.70459 8.25781 1.74233 8.25781C0.780067 8.25781 0 9.03788 0 10.0001C0 10.9624 0.780067 11.7425 1.74233 11.7425Z"
                        fill="white"/>
                    <path
                        d="M1.74233 19.7854C2.70459 19.7854 3.48466 19.0054 3.48466 18.0431C3.48466 17.0808 2.70459 16.3008 1.74233 16.3008C0.780067 16.3008 0 17.0808 0 18.0431C0 19.0054 0.780067 19.7854 1.74233 19.7854Z"
                        fill="white"/>
                </svg>
            </a>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav  level0">
                <li className={`${PATHNAME === "/" ? "active" : ""}`}>
                  <Link to="/">
                    {LanguageCollect.header_homePage}{" "}
                  </Link>
                </li>
                <li>
                  
                </li>
                            <li className={`nav-item dropdown ${PATHNAME.includes("/ban-do-quy-hoach") ||
                                PATHNAME === "/tong-hop-quy-hoach"
                                ? "active"
                                : ""
                                        }`}>
                                <a
                                    className={`dropdown-toggle ${PATHNAME.includes("/ban-do-quy-hoach") ||
                                        PATHNAME === "/tong-hop-quy-hoach"
                                        ? "active"
                                        : ""
                                        }`}
                                    href="#"
                                    id="navbarDropdownMenuLink"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {LanguageCollect.header_lookUpPlanning}
                                </a>
                                <div
                                    className="dropdown-menu animate slideIn"
                                    aria-labelledby="navbarDropdownMenuLink"
                                >
                                    <Link
                                        to={UrlCollect.SyntheticPlanning}
                                        className="dropdown-item"
                                    >
                                        {LanguageCollect.header_syntheticPlanning}
                                    </Link>
                                    {this.state.planningType &&
                                        this.state.planningType.length > 0 &&
                                        this.state.planningType.map((item, index) => (
                                            <Link
                                                key={index}
                                                to={`${UrlCollect.PlanningMap}?key=${item.id}`}
                                                className="dropdown-item"
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                </div>
                            </li>
                            <li className={`nav-item dropdown ${PATHNAME.includes("/tin-tuc") ||
                                PATHNAME === "/tin-tuc" || PATHNAME === "/thu-tuc-hanh-chinh" || PATHNAME === "/chinh-sach-uu-dai"
                                ? "active"
                                : ""
                                }`}>
                                <a
                                    className="dropdown-toggle"
                                    href="#"
                                    id="navbarDropdownMenuLink"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Tin tức
                                </a>
                                <div
                                    className="dropdown-menu animate slideIn"
                                    aria-labelledby="navbarDropdownMenuLink"
                                >
                                    <Link
                                        to="/tin-tuc"
                                        className="dropdown-item"
                                    >
                                        Tin tức chung
                                    </Link>
                                    <Link
                                        to={`${UrlCollect.AdminProceduresList}`}
                                        className="dropdown-item"
                                    >
                                        Thủ tục hành chính
                                    </Link>
                                    <Link
                                        to={`${UrlCollect.IncentivesList}`}
                                        className="dropdown-item"
                                    >
                                        Chính sách ưu đãi
                                    </Link>
                                </div>
                            </li>
                            {
                  //              <li className="has-child">
                  //                  <Link to="/tin-tuc">
                  //                      Tin tức
                  //</Link>
                  //                  <div className="cb"></div>
                  //              </li>
                            }
                            <li hidden={!isLogin} className={`${PATHNAME.includes("/quan-ly-ho-so") ? "active" : ""
                      }`}>
                  <a
                      href="/quan-ly-ho-so/"
                                    
                  >
                    {LanguageCollect.header_recordsManager}
                  </a>
                </li>
                <li className={`${PATHNAME === "/lien-he" ? "active" : ""
                      }`}>
                  <a
                      href="/lien-he"
                      
                  >
                    {LanguageCollect.header_Contact}
                  </a>
                </li>
                <li className="has-child">
                  <Link>
                    <img src="../images/icon-group.png" className="login-icon" />
                    <span id="mobile-account-link">{LanguageCollect.header_account}</span>
                  </Link>
                  <ul className="level1 level1_ext level1_mobile">
                    <li>
                      <a
                          href={UrlCollect.Login}
                          hidden={isLogin}
                      >
                        {LanguageCollect.header_logIn}
                      </a>
                    </li>
                    <li>
                      <a href="#" hidden={!isLogin} onClick={this.onLogout}>
                        {LanguageCollect.header_logout}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {this.state.infoAcount && (
          < RenderModalComponent infoAcount={this.state.infoAcount} showLoading={this.props.showLoading} onLogout={this.onLogout} isShowUserInfoModal={this.state.isShowUserInfoModal} handleCloseUserInfoModal={this.handleCloseUserInfoModal} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      getAllPlanningType: statementActions.PlanningTypeDispatch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderView);
